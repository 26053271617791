<!-- 借用中 -->

<template>
	<list :layout1="layout1" orderStatus="3,5"></list>
</template>

<script>
import list from '../list/index.vue';
export default {
	name: '',
	components: { list },
	data() {
		return {
			layout1: [
				{
					label: '借用人',
					key: 'orderUser'
				},
				{
					label: '借用时间',
					key: 'startTimeText'
				}
			]
		};
	},
	created() {},
	mounted() {},
	methods: {}
};
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
</style>
